<template>
  <va-card :title="$t('dashboard.resume.header')">
    <va-inner-loading :loading="loading">
      <div class="row justify-content-center">
        <div
          class="flex xs12"
          v-for="number of numbers"
          v-bind:key="number.title"
        >
          <va-progress-bar
            :value="number.percent"
            :color="getRandomColor()"
          >
            {{ $t(number.title) }}
            <span>
              <vue-numeric
                separator="."
                :empty-value="0"
                v-model="number.total"
                read-only
              />
            </span>
          </va-progress-bar>
        </div>
      </div>
    </va-inner-loading>
  </va-card>
</template>

<script>
import { mapGetters } from 'vuex'
const VueNumeric = () => import(/* webpackPrefetch: true */ 'vue-numeric')

export default {
  name: 'dashboard-resume-list',
  components: { VueNumeric },
  data () {
    return {
      loading: false,
      error: false,
      numbers: [],
      rawNumbers: null,
      colors: [
        'primary',
        'secondary',
        'success',
        'warning',
        'danger',
      ],
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    'currentUser.permissions' (val) {
      this.fillNumbers()
      if (this.rawNumbers) {
        this.fillRawNumbers()
      }
    },
  },
  created () {
    this.getNumbersData()
  },
  methods: {
    fillNumbers () {
      const n = []
      if (this.currentUser.can('Regions', 'index')) {
        n.push(this.getNumberFormat('regions', 'secondary'))
      }
      if (this.currentUser.can('Countries', 'index')) {
        n.push(this.getNumberFormat('countries', 'primary'))
      }
      if (this.currentUser.can('Districts', 'index')) {
        n.push(this.getNumberFormat('districts', 'danger'))
      }
      if (this.currentUser.can('Teams', 'index')) {
        n.push(this.getNumberFormat('teams', 'info'))
      }
      if (this.currentUser.can('Users', 'index')) {
        n.push(this.getNumberFormat('members', 'success'))
      }
      if (this.currentUser.can('Users', 'index') && this.currentUser.can('DcpiTrainings', 'index')) {
        n.push(this.getNumberFormat('trainers', 'secondary'))
        n.push(this.getNumberFormat('planters', 'success'))
      }
      if (this.currentUser.can('Proyections', 'index')) {
        n.push(this.getNumberFormat('reports', 'danger'))
      }
      if (this.currentUser.can('DcpiTrainings', 'index')) {
        n.push(this.getNumberFormat('trainings', 'warning'))
      }
      if (this.currentUser.can('Mti', 'index')) {
        n.push(this.getNumberFormat('mti', 'primary'))
      }
      if (this.currentUser.can('PreachingPoints', 'index')) {
        n.push(this.getNumberFormat('preaching', 'info'))
      }

      this.numbers = n.slice(0)
    },
    fillRawNumbers () {
      const keys = Object.keys(this.rawNumbers)
      for (const key of keys) {
        const obj = this.numbers.find(n => n.id === key)
        if (obj) {
          obj.loading = false
          obj.total = this.rawNumbers[key].total
          obj.percent = this.rawNumbers[key].percent
        }
      }
    },
    getNumberFormat (id, brand, total) {
      total = total || 0
      const title = 'dashboard.resume.' + id
      return { id, title, brand, total }
    },
    async getNumbersData () {
      this.loading = true
      this.error = false
      let d = null

      try {
        d = await this.$http.get('numbers')
      } catch (e) {
        this.error = true
        return
      }

      this.fillNumbers()
      this.rawNumbers = d.data.data
      this.fillRawNumbers()
      this.loading = false
    },
    getRandomColor () {
      return this.colors[Math.random() * this.colors.length >> 0]
    },
  },
}
</script>

<style scoped lang="scss">
.dashboard-resume-list {
  .inner-loading {
    height: 100%;
  }
}
</style>
